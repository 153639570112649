import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import ScrollToTop from 'components/ScrollToTop';

const Home = React.lazy(() => import('../pages/Home'));
const Reporting = React.lazy(() => import('../pages/Reporting'));
const Backend = React.lazy(() => import('../pages/Backend'));
const Management = React.lazy(() => import('../pages/Management'));
const Contact = React.lazy(() => import('../pages/Contact'));
const Aboutus = React.lazy(() => import('../pages/Aboutus'));
const Pricing = React.lazy(() => import('../pages/Pricing'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const allRoutes = useRoutes([
        {
            path: '/',
            element: <LoadComponent component={Home} />,
        },
        {
            path: '/',
            children: [
                {
                    children: [
                        { path: 'reporting', element: <LoadComponent component={Reporting} /> },
                        { path: 'backend', element: <LoadComponent component={Backend} /> },
                        { path: 'management', element: <LoadComponent component={Management} /> },
                        { path: 'contact', element: <LoadComponent component={Contact} /> },
                        { path: 'about-us', element: <LoadComponent component={Aboutus} /> },
                        { path: 'pricing', element: <LoadComponent component={Pricing} /> },
                    ],
                },
            ],
        },
    ]);
    return (
        <>
            <ScrollToTop />
            {allRoutes}
        </>
    );
};

export default AllRoutes;
